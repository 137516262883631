import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Slideshow.scss";

import slide1 from "../images/Home/CFprofile.webp";
import slide2 from "../images/Home/DSC05287.webp";
import slide3 from "../images/Home/piano-miroir.webp";
import slide16 from "../images/photos/slide16.jpg";

const slideImages = [slide1, slide2, slide3, slide16];

const SlideshowHome = () => {
  return (
    <div className="slide-container">
      <Fade infinite={true} indicators={false} arrows={false} duration={3000}>
        {slideImages.map((e, i) => {
          return (
            <>
              <div key={i} className="each-fade">
                <div className="image-container">
                  <img
                    style={{ maxHeight: "550px", margin: "auto" }}
                    src={e}
                    alt={e}
                  />
                </div>
              </div>
            </>
          );
        })}
      </Fade>
    </div>
  );
};

export default SlideshowHome;
